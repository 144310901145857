<template>
  <form @submit.prevent="save()">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" class="form-control" name="name" v-model.trim="form.name" required />
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-dark" :disabled="submitting">Speichern</button>
    <div class="alert alert-danger mt-3" v-if="errMsg">{{errMsg}}</div>
  </form>
</template>

<script>
import { firestore } from '../firebase';

export default {
  data() {
    return {
      studioId: '',
      courseRoom: null,
      form: {
        name: ''
      },
      submitting: false,
      errMsg: ''
    };
  },
  async created() {
    this.studioId = this.$route.params.id;
    const courseRoomId = this.$route.params.courseRoomId;

    this.form = { ...this.form };

    if (courseRoomId) {
      const courseRoomSnapshot = await firestore
        .collection('studios')
        .doc(this.studioId)
        .collection('courseRooms')
        .doc(courseRoomId)
        .get();

      this.courseRoom = {
        id: courseRoomSnapshot.id,
        ...courseRoomSnapshot.data()
      };
      this.form = { ...this.courseRoom };

      delete this.form.id;
    }
  },
  methods: {
    async save() {
      this.submitting = true;
      this.errMsg = '';

      try {
        if (this.courseRoom) {
          await firestore
            .collection('studios')
            .doc(this.studioId)
            .collection('courseRooms')
            .doc(this.courseRoom.id)
            .update({
              ...this.form
            });
        } else {
          await firestore
            .collection('studios')
            .doc(this.studioId)
            .collection('courseRooms')
            .add({
              ...this.form
            });
        }

        this.$router.push({
          name: 'studio-course-rooms',
          params: {
            id: this.studioId
          }
        });
      } catch (err) {
        this.errMsg = err.message;

        throw err;
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>

<style>
</style>